export const projects = [
  {
    title: "Django React",
    subtitle: "A twitter like app built with Django and React",
    description:"",
    image: "./django.gif",
    link: "https://github.com/pereraniromi1004",
  },
  {
    title: "Data Analysis",
    subtitle: "Python,Power BI, SPSS",
    description:"",
    image: "./python.gif",
    link: "https://github.com/pereraniromi1004",
  },
  {
    title: "Power Apps",
    subtitle: "Power Automate for automated web scraping",
    description:"",
    image: "./powerautomate.gif",
    link: "https://make.powerautomate.com/environments/a7f2c233-e13d-e5cd-902c-c114a6cd0695/flows",
  },
  {
    title: "Machine learning",
    subtitle: "Object detection using Tensorflow",
    description:"",
    image: "./Random Forest 03.gif",
    link: "https://dagshub.com/pereraniromi1004",
  },
];

export const testimonials = [
  {
    quote:
      "One of the key aims of this article is to introduce the reader to a wide range of statistical learning methods that extend far beyond the standard linear regression approach. Why is it necessary to introduce so many different statistical learning approaches, rather than just a single best method? There is no free lunch in statistics: no one method dominates all others over all possible data sets. On a particular data set, one specific method may work best, but some other method may work better on a similar but different data set. ",
    // image: "https://randomuser.me/api/portraits/men/1.jpg",
    name: "Assessing Model Accuracy",
    company: "Statistics",
  },
  {
    quote:
      "The transition from academia to industry could be a nightmare in every student’s life at one point. It is then that we realize the importance of having someone who has been through it all to guide us. Most of the time the relationship between a mentor and a mentee is not confined to academic guidance. Eventually they end up sharing lifelong friendships. We can meet many mentors throughout our lifetime. Also, we can offer mentorship to some other people as well. It is all about offering what we have to someone who needs it at the ideal time.",
    // image: "https://randomuser.me/api/portraits/men/94.jpg",
    name: "The event of being a ‘ScholarX’ mentee",
    company: "Random",
  },
];

export const skills = [
  "Python",
  "React",
  "Angular.js",
  "ASP.NET",
  "Spring Framework",
  "Power Apps",
  "R",
  "Technical Writing"
];
